import * as React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ChakraProvider, theme } from '@chakra-ui/react'
import CreateBoard from './pages/CreateBoardPage'
import BoardPage from './pages/BoardPage'

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<CreateBoard />} />
        <Route path='/:boardId' element={<BoardPage />} />
      </Routes>
    </BrowserRouter>
  </ChakraProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
