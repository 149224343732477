import { Divider, HStack, VStack } from '@chakra-ui/react'
import React from 'react'
import CreateBoard from '../molecules/CreateBoard'
import Header from '../organisms/Header'

const CreateBoardPage = () => {
  return (
    <>
      <Header />
      <VStack p={4} spacing={3} align='left'>
        <HStack>
          <Divider pt={3} />
        </HStack>
        <CreateBoard />
      </VStack>
    </>
  )
}

export default CreateBoardPage
