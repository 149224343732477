import React, { useEffect, useState } from 'react'
import { Box, Divider, HStack, VStack } from '@chakra-ui/react'
import { getBoard, readBoard } from '../services/firebase'
import { todo } from '../models/todo'
import Header from '../organisms/Header'
import { useParams } from 'react-router-dom'
import CheckTask from '../molecules/CheckTask'
import CreateTask from '../molecules/CreateTask'
import CreateBoard from '../molecules/CreateBoard'

const BoardPage: React.FC = (props) => {
  const [todos, setTodos] = useState<todo[]>([])
  const [rebuild, setRebuild] = useState<boolean>(true)
  const [isExpired, setIsExpired] = useState(false)
  const [title, setTitle] = useState('')
  const params = useParams()
  const boardId = params.boardId as string

  useEffect(() => {
    const read = async () => {
      const data = await readBoard(boardId)
      const tmpTitle = data!.title ? data!.title : null
      const expiredAt = new Date(data!.expiredAt.seconds * 1000)
      const now = new Date()
      if (tmpTitle) setTitle(tmpTitle)

      if (now > expiredAt) {
        setIsExpired(true)
      } else {
        setIsExpired(false)

        if (rebuild) {
          const fetchTodos = async () => {
            setTodos((await getBoard(boardId)) as unknown as todo[])
            setRebuild(false)
          }
          fetchTodos()
        }
      }
    }
    read()
  }, [rebuild, boardId])

  return (
    <>
      <Header title={title} />
      {!isExpired ? (
        <VStack h={1000} p={4} spacing={3} align='left'>
          <CreateTask onCreate={() => setRebuild(true)} />
          <HStack>
            <Divider pt={3} />
          </HStack>
          {todos
            .sort((el1, el2) => {
              const el1Date = new Date(el1?.createdAt.seconds * 1000)
              const el2Date = new Date(el2?.createdAt.seconds * 1000)
              if (el1Date > el2Date) return 1
              return -1
            })
            .map((todo: todo, index: number) => {
              return (
                <CheckTask
                  boardId={boardId}
                  todo={todo}
                  key={index}
                  onDelete={() => setRebuild(true)}
                  onChecked={() => setRebuild(true)}
                />
              )
            })}
        </VStack>
      ) : (
        <>
          <VStack p={4} spacing={3} align='left'>
            <HStack>
              <Box>有効期限が切れました。</Box>
            </HStack>
            <CreateBoard />
          </VStack>
        </>
      )}
    </>
  )
}

export default BoardPage
