import React, { useState } from 'react'
import { FormControl } from '@chakra-ui/form-control'
import { Input } from '@chakra-ui/input'
import { Button } from '@chakra-ui/button'
import { HStack } from '@chakra-ui/react'
import { createTask } from '../services/firebase'
import { useLocation } from 'react-router'

type propType = {
  onCreate: () => void
}
const CreateTask: React.FC<propType> = (props) => {
  const [newTask, setNewTask] = useState<string>('')
  const location = useLocation()
  return (
    <HStack spacing={2}>
      <FormControl size='xs'>
        <Input
          id='addtask'
          placeholder='追加するタスクを記入...'
          value={newTask}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setNewTask(e.target.value)
          }}
        />
      </FormControl>
      <Button
        mt={4}
        colorScheme='teal'
        size='md'
        type='submit'
        isDisabled={newTask.length === 0}
        onClick={async () => {
          await createTask(
            location.pathname !== '/' ? location.pathname : '1',
            newTask,
            false
          )
          setNewTask('')
          props.onCreate()
        }}
      >
        追加
      </Button>
    </HStack>
  )
}

export default CreateTask
