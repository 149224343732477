import { initializeApp } from 'firebase/app'
import { getAuth, signInAnonymously } from 'firebase/auth'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

// Initialize Firebase
initializeApp(firebaseConfig)
const signIn = async () => {
  const auth = getAuth()
  await signInAnonymously(auth)
}
signIn()
export const db = getFirestore()

export const createBoard = async (boardId: string, title: string) => {
  const now = new Date()
  const nextWeek = now.setDate(now.getDate() + 7)
  const nextWeekTimestamp = new Timestamp(nextWeek / 1000, 0)
  try {
    await setDoc(doc(db, 'tasks', boardId), {
      title: title,
      createdAt: serverTimestamp(),
      expiredAt: nextWeekTimestamp,
    })
    console.log('[create] Document written ')
  } catch (e) {
    console.error('[create]Error adding document: ', e)
    return false
  }
  return true
}

export const createTask = async (
  boardId: string,
  task: string,
  isChecked: Boolean
) => {
  try {
    const docRef = await addDoc(collection(db, 'tasks', boardId, 'task'), {
      task: task,
      isChecked: isChecked,
      createdAt: serverTimestamp(),
    })
    console.log('[create] Document written with ID: ', docRef.id)
  } catch (e) {
    console.error('[create]Error adding document: ', e)
    return false
  }
  return true
}

export const readBoard = async (boardId: string) => {
  const docRef = doc(db, 'tasks', boardId)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    return null
  }
}

export const updateTask = async (
  boardId: string,
  taskId: string,
  isChecked: boolean
) => {
  try {
    const updateRef = doc(db, 'tasks', boardId, 'task', taskId)
    await updateDoc(updateRef, {
      isChecked: isChecked,
    })
    console.log('[Update] Document written with ID: ', updateRef.id)
  } catch (e) {
    console.error('[Update]Error adding document: ', e)
    return false
  }
  return true
}

export const deleteTask = async (boardId: string, taskId: string) => {
  try {
    const deleteRef = doc(db, 'tasks', boardId, 'task', taskId)
    await deleteDoc(deleteRef)
    console.log('[Delete] Document deleted with ID: ', deleteRef.id)
  } catch (e) {
    console.error('[Delete]Error deleting document: ', e)
    return false
  }
  return true
}

export const getQuerySnapshot = async (taskid: string) => {
  return await getDocs(collection(db, 'tasks', taskid, 'task'))
}

export const getBoard = async (taskid: string) => {
  const querySnapshot = await getQuerySnapshot(taskid)
  const array = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    task: doc.data().task as string,
    isChecked: doc.data().isChecked as Boolean,
    createdAt: doc.data().createdAt as Timestamp,
  }))
  return array
}
