import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Wrap,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { createBoard } from '../services/firebase'

const CreateTasks: React.FC = () => {
  const navigate = useNavigate()
  const [title, setTitle] = useState<string>('')
  return (
    <>
      <HStack>
        <FormControl>
          <FormLabel>タイトル</FormLabel>
          <Input
            id='addBoard'
            placeholder='タイトルを入力...'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTitle(e.target.value)
            }}
          />
        </FormControl>
      </HStack>
      <Wrap align='center'>
        <Button
          mt={4}
          colorScheme='teal'
          size='lg'
          type='submit'
          onClick={async () => {
            const S =
              'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
            const N = 20
            const query = Array.from(crypto.getRandomValues(new Uint8Array(N)))
              .map((n) => S[n % S.length])
              .join('')
            await createBoard(query, title)
            navigate('/' + query)
          }}
        >
          新しいチェックリストをつくる
        </Button>
      </Wrap>
    </>
  )
}

export default CreateTasks
