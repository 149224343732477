import { Flex } from '@chakra-ui/layout'
import { Checkbox, CloseButton, Grid, GridItem, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { todo } from '../models/todo'
import { updateTask, deleteTask } from '../services/firebase'
type propType = {
  todo: todo
  boardId: string
  onDelete: () => void
  onChecked: () => void
}

const CheckTask: React.FC<propType> = (props) => {
  const [isChecked, setIsChecked] = useState(props.todo.isChecked)
  useEffect(() => {
    if (props.todo.isChecked !== isChecked) setIsChecked(props.todo.isChecked)
  }, [isChecked, props.todo, props.todo.isChecked])

  return (
    <Flex>
      <Checkbox
        colorScheme='green'
        size='lg'
        spacing={6}
        isChecked={isChecked}
        onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
          await updateTask(props.boardId, props.todo.id!, !isChecked)
          props.onChecked()
        }}
      >
        {isChecked ? (
          <Grid templateColumns='repeat(10, 1fr)' gap={4}>
            <GridItem colStart={1} colEnd={10}>
              <Text as='s'>{props.todo.task}</Text>
            </GridItem>
            <GridItem colStart={10} colEnd={10}>
              <CloseButton
                color='gray.300'
                onClick={async () => {
                  await deleteTask(props.boardId, props.todo.id!)
                  props.onChecked()
                }}
              />
            </GridItem>
          </Grid>
        ) : (
          <Grid templateColumns='repeat(10, 1fr)' gap={4}>
            <GridItem colStart={1} colEnd={10}>
              <Text>{props.todo.task}</Text>
            </GridItem>
            <GridItem colStart={10}>
              <CloseButton
                color='gray.300'
                onClick={async () => {
                  await deleteTask(props.boardId, props.todo.id!)
                  props.onDelete()
                }}
              />
            </GridItem>
          </Grid>
        )}
      </Checkbox>
    </Flex>
  )
}

export default CheckTask
