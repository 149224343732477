import { HStack, Text } from '@chakra-ui/layout'
import { Box, Button, Flex, Link, Spacer } from '@chakra-ui/react'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { FiShare } from 'react-icons/fi'

type propType = {
  title?: string
}
const Header: React.FC<propType> = (props) => {
  const location = useLocation()
  const title = props.title ? props.title : ''
  return (
    <Flex align='center'>
      <HStack pl={6} align='center'>
        <Text fontSize='40px' colorScheme='teal'>
          <Link href='/' variant='outline'>
            linetodo
          </Link>
        </Text>
        <Text fontSize='20px' colorScheme='teal'>
          {title}
        </Text>
      </HStack>
      <Spacer />
      {navigator.share && location.pathname !== '/' && (
        <Box pr={6}>
          <Button
            colorScheme='teal'
            variant='outline'
            size='md'
            aria-label='Search database'
            leftIcon={<FiShare />}
            onClick={() => {
              navigator.share({
                title: 'ちょこメモ',
                text: title,
                url: 'http://chokomemo.zunchiki.com' + location.pathname,
              })
            }}
          >
            共有
          </Button>
        </Box>
      )}
    </Flex>
  )
}

export default Header
